<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <span class="d-none d-sm-inline-block">{{ $t('All rights Reserved') }}</span>
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :href="platformUrl()"
        target="_blank"
      >{{ platformName() }}</b-link>
    </span>

    <!--    <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with-->
    <!--      <feather-icon-->
    <!--        icon="HeartIcon"-->
    <!--        size="21"-->
    <!--        class="text-danger stroke-current"-->
    <!--      />-->
    <!--    </span>-->
    <span class="float-md-right d-none d-md-block">{{ $t('Version') }} {{ $store.state.storeConfig.release.version }}
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { platformName, platformUrl } from '../../utils/utils'

export default {
  components: {
    BLink,
  },
  methods: { platformName, platformUrl },
}
</script>
