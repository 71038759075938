<template>
  <div>
    <!-- info -->
    <b-alert
      v-if="isDemoStore()"
      variant="info"
      show
    >
      <div class="alert-body">
        <span>{{ $t('message.demoStoreNotice.text') }} <a :href="registerUrl()">{{ $t('message.demoStoreNotice.action') }}</a></span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { dashboardHost } from '@core/utils/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BAlert,
  },
  methods: {
    isDemoStore() {
      return this.$store.state?.storeConfig?.data?.is_demo
    },
    registerUrl() {
      return `https://${dashboardHost()}/register?utm_source=demo_alert`
    },
  },
}
</script>
