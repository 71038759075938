<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="flagOfLocale(currentLocale.locale)"
        height="22px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      :class="`${localeObj.locale}-locale`"
      @click="switchLocaleTo(localeObj.locale)"
    >
      <b-img
        :src="flagOfLocale(localeObj.locale)"
        height="22px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { setLocale } from '@/libs/i18n'
import axios from '@axios'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    flagOfLocale() {
      return locale => {
        const country = {
          en: 'US',
          ar: this.$store.state.appConfig.localization.arabicCountry,
        }[locale] ?? 'US'
        return this.countriesFlags[country]
      }
    },
  },
  methods: {
    switchLocaleTo(locale) {
      setLocale(locale, this.$i18n)
    },
  },
  setup() {
    /* eslint-disable global-require */

    const countriesFlags = {
      US: require('@/assets/images/flags/united-states.svg'),
      EG: require('@/assets/images/flags/egypt.svg'),
      SA: require('@/assets/images/flags/saudi-arabia.svg'),
      KW: require('@/assets/images/flags/kuwait.svg'),
      QA: require('@/assets/images/flags/qatar.svg'),
      BH: require('@/assets/images/flags/bahrain.svg'),
    }

    const locales = [
      {
        locale: 'en',
        img: countriesFlags.USA,
        name: 'English',
      },
      {
        locale: 'ar',
        img: countriesFlags.EGY,
        name: 'عربي',
      },
    ]
    /* eslint-disable global-require */

    return {
      countriesFlags,
      locales,
    }
  },
}
</script>

<style>
  .dropdown-language img {
    border-radius: 25%;
  }
</style>
