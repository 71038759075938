<template>
  <b-nav-item
    v-b-tooltip.hover="$t('navbar.actions.theme')"
    @click="skin = (skin === 'light' ? 'semi-dark' : (skin === 'semi-dark' ? 'dark' : 'light'))"
  >
    <component
      :is="skin === 'semi-dark' ? 'material-icon' : 'feather-icon'"
      size="21"
      :icon="skin === 'light' ? 'SunIcon' : (skin === 'semi-dark' ? 'contrast' : 'MoonIcon')"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from 'vue'
import { BNavItem, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
