export default [
  {
    header: 'sidebar.headers.marketing',
  },
  {
    title: 'sidebar.affiliate_marketing',
    route: 'affiliates.list',
    icon: 'TrendingUpIcon',
    iconMaterial: 'handshake',
    // tag: 'New',
    tagVariant: 'light-primary',
  },
  // {
  //   title: 'sidebar.offers',
  //   route: null,
  //   icon: 'TagIcon',
  //   tag: 'Soon',
  //   tagVariant: 'light-secondary',
  // },
  {
    title: 'sidebar.discount_coupons',
    route: 'coupons.list',
    icon: 'PercentIcon',
    // tag: 'New',
    tagVariant: 'light-primary',
  },
  // {
  //   title: 'sidebar.adv_campaigns',
  //   route: null,
  //   icon: 'TrendingUpIcon',
  //   tag: 'Soon',
  //   tagVariant: 'light-secondary',
  // },
  {
    title: 'sidebar.abandoned_carts',
    route: null,
    icon: 'ShoppingBagIcon',
    tag: 'Soon',
    tagVariant: 'light-secondary',
  },
]
