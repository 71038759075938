export default [
  {
    header: 'sidebar.headers.customers',
  },
  {
    title: 'modules.customers.p_c',
    route: 'customers.list',
    icon: 'UsersIcon',
  },
]
