import store from '@/store'

export default [
  {
    header: 'sidebar.headers.orders',
  },
  {
    title: 'modules.orders.p_c',
    route: 'orders.list',
    icon: 'ShoppingCartIcon',
    tag: parseInt(store.state.appConfig.layout.menu.counters.orders, 10) || '',
    tagVariant: 'light-primary',
    tagId: 'menu_orders_count',
  },
]
