export default [
  {
    header: 'sidebar.headers.products',
  },
  {
    title: 'modules.products.p_c',
    route: 'products.list',
    icon: 'BoxIcon',
  },
  {
    title: 'modules.product_categories.p_c',
    route: 'products_categories.list',
    iconMaterial: 'category',
  },
]
