<template>
  <li class="nav-item nav-support">

    <!-- Icon -->
    <a
      v-b-tooltip.hover="$t('navbar.actions.support')"
      v-b-modal.support-modal
      class="nav-link nav-link-search"
    >
      <feather-icon
        icon="LifeBuoyIcon"
        size="21"
      />
    </a>

    <!-- basic modal -->
    <b-modal
      id="support-modal"
      :title="$t(`navbar.support.need_support`)"
      centered
      hide-footer
    >
      <b-container fluid>
        <b-row>
          <b-col
            sm="6"
            cols="6"
            class="mx-auto"
          >
            <img
              :src="supportImage"
              alt="support"
              height="auto"
              width="100%"
            >
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <h5 v-t="`navbar.support.contact_us`" />
            <b-button
              href="https://www.facebook.com/KssibCom/"
              target="_blank"
              variant="facebook"
              block
            >
              <feather-icon
                icon="FacebookIcon"
                class="mr-50"
              />
              <span v-t="`navbar.support.on_facebook`" />
            </b-button>
            <hr class="my-1">
            <h5 v-t="`navbar.support.request_a_contact`" />
            <b-button
              :href="`https://share.hsforms.com/1g1scpnBCR5mjxGWj754P4gdjvfe?website=${(shop().url || 'kssib.com').replace('https:', '').replace('http:', '').replaceAll('/', '')}`"
              target="_blank"
              variant="primary"
              block
            >
              <material-icon
                icon="confirmation_number"
                size="14"
                class="mr-50"
              />
              <span v-t="`navbar.support.create_support_ticket`" />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </li>
</template>

<script>
import {
  BButton,
  BCol,
  BContainer,
  BModal, BRow, VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import { shop } from '@core/utils/utils'

const supportImage = require('@/assets/images/illustration/support.webp')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BContainer,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  methods: { shop },
  setup() {
    return {
      supportImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
