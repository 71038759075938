<template>
  <b-nav-item-dropdown
    v-if="itemData"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ itemData.fullName || itemData.username }}
        </p>
        <span class="user-status">{{ itemData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :text="avatarText(itemData.fullName)"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!itemData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      v-if="$store.state.storeConfig.isUrlReady"
      :href="shop().url || '#'"
      target="_blank"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HomeIcon"
        class="mr-50"
      />
      <span>
        {{ $t('My Store') }}
        <feather-icon
          size="6"
          icon="ExternalLinkIcon"
          class="ml-25"
        />
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'subscription' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>{{ $t('sidebar.subscriptions') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'pages-profile'}"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="UserIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Profile</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'apps-email' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="MailIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Inbox</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'apps-todo' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="CheckSquareIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Task</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'apps-chat' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="MessageSquareIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Chat</span>-->
    <!--    </b-dropdown-item>-->

    <!--    <b-dropdown-divider />-->

    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'pages-account-setting' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="SettingsIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Settings</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'pages-pricing' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="CreditCardIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Pricing</span>-->
    <!--    </b-dropdown-item>-->
    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'pages-faq' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="HelpCircleIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>FAQ</span>-->
    <!--    </b-dropdown-item>-->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span v-t="'Logout'" />
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useSession from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'
import { shop } from '@core/utils/utils'

export default {
  components: {
    BDropdownDivider,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      itemData: getUserData(),
      avatarText,
    }
  },
  methods: {
    shop,
    logout() {
      // TODO: Use jwtService method
      // Remove itemData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useSession.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useSession.jwtConfig.storageRefreshTokenKeyName)

      // Remove itemData from localStorage
      localStorage.removeItem('itemData')

      useSession.revokeToken()

      // Reset ability
      this.$ability.update(initialAbility)

      useSession.redirectToLoginPage()
    },
  },
}
</script>
